.main {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 52px);
  overflow: hidden;
  background-color: #fff;
}

// search Area
.searchArea {
  position: relative;
  width: 100%;
  padding: 0 12px;

  .searchAreaBox {
    position: relative;
    padding: 57px 20px 60px;
    text-align: center;
    background-color: #e8f3ff;
    background-image: url("../images/h5-banner-cover.png");
    background-repeat: no-repeat;
    background-position: -117px 48px;
    background-size: 425px 377px;
    border-radius: 16px;
  }

  .searchAreaHeader {
    position: relative;
    z-index: 2;
    margin-bottom: 32px;
  }

  .searchAreaTitle {
    position: relative;
    z-index: 2;
    display: none;
    margin-bottom: 24px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    color: #000;
    text-align: center;
  }

  .searchAreaHide {
    display: none;
  }

  .searchAreaInput {
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: initial;

    :global {
      .ant-select-selector {
        background: transparent !important;
      }
    }
  }

  .searchAreaLocation {
    height: 48px;
    padding: 4px 8px 4px 12px;
    margin-top: 16px;
    border: 2px solid #e1eeff;
    border-radius: 40px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  }

  .searchAreaPopular {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
    text-align: left;
    letter-spacing: 0.12px;
  }

  .searchAreaList {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    height: 95px;
    margin-top: 32px;
    overflow: hidden;
  }

  .searchAreaItem {
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%),
      rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 102, 255, 0.1);
    border-radius: 12px;
  }

  .searchAreaItemLink {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .searchAreaItemText {
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #4b4c4d;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .searchAreaImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  .searchAreaImage img {
    top: -20px !important;
    left: 20px !important;
  }

  :global {

    /* stylelint-disable-next-line selector-class-pattern */
    .mobile-input-location-placeholder {
      color: #b8b8b8 !important;
    }
  }
}

// jobsHiring
.jobsHiring {
  position: relative;
  display: flex;
  flex-direction: column;

  .jobsHiringBox {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 0;
    margin: 0 auto;

    // background: #f8f9fa;
  }

  .jobsHiringLeftBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .jobsHiringRightTop {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }

  .jobsHiringTop {
    display: block;
    padding: 48px 20px 28px 28px;
  }

  .jobsHiringTopTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #121212;
  }

  .jobsHiringTopDesc {
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #3b3c3c;
  }

  .jobsHiringTopLink {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #1c70ee;

    // text-decoration-line: underline;
  }

  .jobsHiringContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 16px;
  }

  .jobsHiringFooter {
    display: flex;
    justify-content: center;

    // margin-top: 10px;
  }

  .jobsHiringFooterLink {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #2378e5;
    text-decoration: none;
  }

  // .jobsHiringFooter svg path {
  //   fill: #2378e5;
  // }

  .jobsHiringFooterMore {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 20px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #111212;

    svg {
      margin-top: 2px;
    }
  }
}

// job card
.jobCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  border: 1px solid #f4f5f6;
  border-radius: 12px;
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

  .jobCardLink {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    color: #121212;
    text-decoration: none;
  }

  .jobCardLine {
    display: flex;
    width: 100%;
    margin: 12px 0;
    border-top: 1px solid #ebeef0;
  }

  .jobCardTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #000;
  }

  .jobCardUrgent {
    display: inline-block;
    height: 22px;
    padding: 0 6px 0 4px;
    margin-left: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #000;
    vertical-align: text-bottom;
    background: #3ceaf6;
    border-radius: 6px;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      vertical-align: text-bottom;
    }
  }

  .jobCardSalary {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 1px;
  }

  .jobCardSalaryValue {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #1c70ee;
    text-align: right;
  }

  .jobCardSalaryType {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #111212;
    text-align: right;
  }

  .jobCardCompany {
    margin-top: 3px;
    margin-bottom: 6px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #3b3c3c;
  }

  .jobCardLocation {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 4px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3b3c3c;
  }

  .jobCardLocationItem {
    display: inline-block;
    height: 28px;
    padding: 0 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #3b3c3c;
    background: #f8f9fa;
    border-radius: 8px;
  }

  .jobCardLocationRemote {
    color: #3a90ff;
  }

  .jobCardFooter {
    display: flex;
    flex-direction: row;
    align-items: center;

    // margin-top: 30px;
  }

  .jobCardFooterAvatar {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .jobCardFooterAvatarImage {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .jobCardFooterAvatarImage.offline {
    border-color: #e4e4e4;
  }

  .jobCardFooterAvatarOnline {
    position: absolute;
    right: 2px;
    bottom: 0;
    display: block;
    width: 6px;
    height: 6px;
    content: "";
    background: #34c759;
    border: 1px solid #fff;
    border-radius: 50%;
  }

  .jobCardFooterInfo {
    display: flex;
    flex: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #4b4c4d;
  }

  .jobCardFooterInfoText {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .jobCardFooterInfoName {
    display: inline;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #000;
  }

  .jobCardFooterInfoDot {
    line-height: 24px;
    color: #acaeb1;
  }

  .jobCardFooterInfoJobTitle {
    display: inline;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #646566;
  }
}

// Companies
.companies {
  padding: 24px 16px 0;

  // background: #f8f9fa;

  .companiesTitle {
    padding: 0 12px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #111212;
  }

  .companiesDesc {
    padding: 0 12px;
    padding-bottom: 24px;
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #3b3c3c;
  }

  .companiesDesc span {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    color: #1c70ee;
  }

  .companiesList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }

  .companiesItem {
    position: relative;
    display: flex;
    gap: 12px;
    align-items: flex-start;
    padding: 24px 12px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f4f5f6;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  }

  .companiesMore {
    display: flex;
    justify-content: center;
    margin: 28px auto 60px;
  }

  .companiesViewLink {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #2378e5;
    text-decoration: none;
  }

  .jobsHiringNoMore {
    color: #585a5a;
  }

  .companiesMoreView {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #111212;

    svg {
      margin-top: 2px;
    }
  }
}

.companyCard {
  display: flex;
  gap: 12px;
  width: 100%;
  height: 100%;

  .companyImage {
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ebeef0;
    border-radius: 10px;
  }

  .companyImage img {
    flex-shrink: 0;
  }

  .companyCardContent {
    flex: 1;
    width: 0;
    margin-top: -2px;
  }

  .companyCardTitle {
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .companyCardInfo {
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #4b4c4d;
    text-overflow: ellipsis;
    letter-spacing: 0.14px;
    white-space: nowrap;
  }

  .companyCardDot {
    margin: 0 4px;
    color: #4b4c4d;
  }

  .companyCardFooter {
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .companyFooterJobs {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1c70ee;
  }

  .companyFooterOpening {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #4b4c4d;
  }
}